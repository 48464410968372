<template>
  <div>
    <v-container>
      <div class="text-center" v-for="(item, index) in examConfigs" :key="index">
        <p>{{ item.name }}</p>
        <v-btn color="primary" @click="goQuestionPage(item)">Nhập câu hỏi</v-btn>
      </div>
    </v-container>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {
  GET_ALL_EXAM_CONFIG
} from "@/store/exams.module";
import {EXAM_CONFIG_ID} from "@/common/constants";
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";

export default {
  components: {},
  data() {
    return {
      EXAM_CONFIG_ID:EXAM_CONFIG_ID
    };
  },
  computed: {
    ...mapGetters({
      examConfigs: "examConfigList",
    }),

  },
  watch: {
  },
  async created() {
    await this.$store.dispatch(SET_BREADCRUMB, [{ title: "Ngân hàng đề thi",titledad:'Đề thi' }]);
    await this.$store.dispatch(GET_ALL_EXAM_CONFIG);
  },
  methods: {
    goQuestionPage(item){
      if(item.id === EXAM_CONFIG_ID.topicII_writing){
        this.$router.push({
          path: "/question/list",
          query: {
            exam_config_id: item.id,
            group_question_id: null,
          },
        });
      }else {
        this.$router.push({
          path: "/group-question/list",
          query: {exam_config_id: item.id},
        });
      }
    }
  }
};
</script>

<style scoped>

</style>
